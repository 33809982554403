<template>
  <CustomDialog
    v-model="dialog"
    @button1="close"
    @button2="save"
    :title="title"
    max-width="1024"
    button1-text="Close"
    :button2-text="isEdit ? 'Update' : 'Save'"
    :main-btn-disabled="!valid"
  >
    <template v-slot:content>
      <v-card flat>
        <v-form ref="form" v-model="valid">
          <v-card-text>
            <v-row dense align="stretch" justify="start">
              <v-col cols="12" md="4" sm="12" class="fill-height">
                <div dense class="mb-3">
                  <label class="subtitle-2 primary--text">
                    {{ title_label }}
                  </label>
                  <v-text-field
                    hide-details="auto"
                    dense
                    :rules="[requiredRule()]"
                    v-model="mapping.title"
                    outlined
                    :placeholder="`Enter ${title_label}`"
                  ></v-text-field>
                </div>
                <div>
                  <label class="subtitle-2 primary--text">
                    {{ image_label }}
                  </label>
                  <v-sheet
                    min-height="200"
                    outlined
                    class="rounded-5 pa-2 fill-height"
                  >
                    <v-hover v-slot:default="{ hover }">
                      <v-img
                        contain
                        :src="
                          preview_image
                            ? preview_image
                            : settings.noImagePreview
                        "
                        max-height="250"
                      >
                        <div
                          v-if="hover"
                          class="
                            mx-auto
                            d-flex
                            align-center
                            justify-center
                            fill-height
                          "
                        >
                          <v-icon
                            v-if="!uploading"
                            @click="$refs.fileInput.$refs.input.click()"
                            size="50"
                            color="primary"
                          >
                            mdi-camera
                          </v-icon>
                          <v-icon
                            v-else-if="uploading"
                            size="50"
                            color="primary"
                          >
                            mdi-spin mdi-loading
                          </v-icon>
                        </div>
                      </v-img>
                    </v-hover>
                  </v-sheet>
                  <v-file-input
                    ref="fileInput"
                    accept="image/*"
                    class="d-none"
                    @change="handleFileChange"
                  ></v-file-input>
                </div>
              </v-col>
              <v-col cols="12" md="8" sm="12">
                <label class="subtitle-2 primary--text">
                  {{ content_label }}
                </label>
                <Editor
                  required
                  min-height="240"
                  editable
                  v-model="mapping.body"
                ></Editor>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
      </v-card>
    </template>
  </CustomDialog>
</template>
<script>
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import { mapActions, mapGetters } from 'vuex'
import request from '@/services/axios_instance'
import _ from 'lodash'
import Editor from '@/common/Editor/Editor.vue'
export default {
  name: 'PostDialog',
  components: { CustomDialog, Editor },
  props: {
    value: { type: Boolean, default: false },
    type: { type: String, default: 'post' },
    item: { type: Object }
  },
  data: () => ({
    dialog: false,
    valid: false,
    attachment: null,
    uploading: false,
    preview_image: null,
    mapping: { title: null, body: null, preview_image: null }
  }),
  computed: {
    isEdit() {
      return this.item ? true : false
    },
    title() {
      if (this.type === 'faqs')
        return this.isEdit
          ? `Update : ${this.item.title}`
          : 'Create New FAQs Question'
      else if (this.type === 'updates')
        return this.isEdit
          ? `Update : ${this.item.title}`
          : 'Create New Post Updates'
      else if (this.type === 'upcoming-updates')
        return this.isEdit
          ? `Update : ${this.item.title}`
          : `Create New 'Upcoming Updates' post`
      return this.isEdit ? `Update : ${this.item.title}` : 'Create New Post'
    },
    payload() {
      return {
        title: this.mapping.title,
        body: this.mapping.body,
        type: this.type,
        attachment: this.attachment
      }
    },
    title_label() {
      if (this.type === 'faqs') return 'Question'
      else if (this.type === 'updates') return 'Updates Title'
      else if (this.type === 'upcoming-updates') return 'Upcoming Updates Title'
      return 'Post Title'
    },
    image_label() {
      if (this.type === 'faqs') return 'Icon (Optional)'
      else if (this.type === 'updates') return 'Preview Image (Optional)'
      else if (this.type === 'upcoming-updates')
        return 'Preview Image (Optional)'
      return 'Preview Image (Optional)'
    },
    content_label() {
      if (this.type === 'faqs') return 'Answer'
      else if (this.type === 'updates') return 'Updates Description'
      else if (this.type === 'upcoming-updates')
        return 'Upcoming Updates Description'
      return 'Post Content'
    }
  },
  watch: {
    value(val) {
      this.dialog = val
      if (val && !this.isEdit) {
        this.mapping = { title: null, body: null, preview_image: null }
        this.preview_image = null
        this.attachment = null
      }
    },
    dialog(val) {
      this.$emit('input', val)
    },
    item: {
      handler: function (val) {
        this.mapping = {
          title: !val ? null : val.title,
          body: !val ? null : val.content,
          preview_image: !val ? null : val.preview_image
        }
        this.preview_image = !val ? null : val.preview_image
        this.attachment = val && val.attachment ? val.attachment : null
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapActions('supports', ['deleteTag', 'addTag']),
    close() {
      this.dialog = false
    },
    save() {
      this.$emit(this.isEdit ? 'update' : 'save', this.payload)
    },
    handleFileChange(file) {
      if (file) {
        if (!file.type.includes('image/')) {
          this.appSnackbar('File is not a supported image type', 'error')
          return
        }
        if (file.size > this.settings.fileSizeMaxUploadLimit) {
          this.appSnackbar(
            `Image file size max limit is ${this.settings.fileSizeMaxUploadInMB}MB`,
            'error'
          )
          return
        }
        this.uploading = true
        var formData = new FormData()
        formData.append('file', file)
        request
          .post('api2/attachments/dropzone', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
          })
          .then(({ data }) => {
            this.attachment = data
            this.preview_image = data.url_inline
          })
          .finally(() => (this.uploading = false))
      }
    }
  }
}
</script>
<style></style>
