<template>
  <v-card flat class="rounded-10 faqs-supports">
    <v-card-title class="fullwidth">
      <v-card dark dense flat class="fullwidth rounded-tr-10">
        <v-card-title class="primary lighten-1">
          <v-btn
            v-if="can_handle_tickets"
            absolute
            bottom
            color="primary"
            small
            right
            @click="handleCreate('faqs')"
            fab
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <h3 class="text-h6 fw-700 text-center grow">
            Frequently Ask Questions
          </h3>
        </v-card-title>
      </v-card>
    </v-card-title>
    <v-card-text class="d-flex align-center justify-center">
      <Empty v-if="!faqs.length" min-height="100vh" headline></Empty>
      <v-expansion-panels v-else flat multiple dense>
        <v-expansion-panel
          class="border-1 border-color--grey"
          v-for="(item, i) in faqs"
          :key="i"
        >
          <v-expansion-panel-header
            v-slot="{ open }"
            class="primary--text fw-700 fs-17"
          >
            <div class="d-flex align-center justify-space-between">
              <div class="d-flex align-center justify-start">
                <v-icon left>
                  {{ open ? 'mdi-menu-down' : 'mdi-menu-right' }}
                </v-icon>
                {{ item.title }}
              </div>
              <div v-if="can_handle_tickets">
                <HelpToolTip
                  text="Edit"
                  icon="mdi-pencil"
                  @click:icon="handleEdit(item)"
                ></HelpToolTip>
                <HelpToolTip
                  text="Delete"
                  icon="mdi-delete"
                  @click:icon="handleDelete(item)"
                ></HelpToolTip>
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="grey lighten-3 rounded-5 pa-1">
            <Editor
              class="fullwidth"
              :editable="false"
              :value="item.content"
            ></Editor>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
    <v-card-actions class="d-flex align-center justify-center">
      <v-btn
        v-if="faqs_next_page_url"
        :loading="fetching_more_faqs"
        @click="fetchMoreFaqs"
        depressed
        :disabled="fetching_more_faqs"
        class="text-none px-5"
        color="primary"
      >
        Load more FAQs
      </v-btn>
    </v-card-actions>
    <v-card-text class="d-flex align-center justify-center flex-column">
      <h2 class="primary--text mb-2 fw-700">You still have a question?</h2>
      <span class="secondary--text fw-500">
        If you cannot find a question in our FAQ, you can always contact us. We
        will answer to you shortly!
      </span>
      <div class="fullwidth mt-5 d-flex align-center justify-center">
        <v-sheet
          color="secondary lighten-4"
          min-width="250"
          min-height="200"
          class="
            d-flex
            rounded-5
            ma-md-2 ma-sm-1
            pa-5
            flex-column
            align-center
            justify-center
          "
        >
          <v-icon size="40">mdi-phone</v-icon>
          <strong class="fw-900 primary--text fs-20 my-2">
            {{ settings.support_number }}
          </strong>
          <span class="secondary--text">We are always happy to help! </span>
        </v-sheet>
        <v-sheet
          color="secondary lighten-4"
          min-width="250"
          min-height="200"
          class="
            d-flex
            rounded-5
            ma-md-2 ma-sm-1
            pa-5
            flex-column
            align-center
            justify-center
          "
        >
          <v-icon size="40">mdi-email</v-icon>
          <strong class="fw-900 primary--text fs-20 my-2">
            {{ settings.support_email }}
          </strong>
          <span class="secondary--text">Best way to get answer faster!</span>
        </v-sheet>
      </div>
    </v-card-text>
    <PostDialog
      :type="activePostType"
      :item="activePost"
      v-model="post_dialog"
      @save="savePost"
      @update="updatePost"
    ></PostDialog>
  </v-card>
</template>

<script>
import PostDialog from '../components/PostDialog.vue'
import request from '@/services/axios_instance'
import { support_mixin } from '../support_mixin'
import Editor from '@/common/Editor/Editor.vue'
export default {
  mixins: [support_mixin],
  components: { PostDialog, Editor },
  data: () => ({
    post_dialog: false,
    activePostType: 'faqs',
    activePost: null,

    faqs: [],
    faqs_next_page_url: null,
    fetching_faqs: false,
    fetching_more_faqs: false,

    recent_posts: [],
    fetching_recent_posts: false
  }),
  created() {
    this.fetchFaqs()
  },
  methods: {
    fetchFaqs() {
      this.fetching_faqs = true
      request
        .get(`api/supports/post?type=faqs`)
        .then(({ data }) => {
          this.faqs = data.data
          this.faqs_next_page_url = data.next_page_url
        })
        .finally(() => (this.fetching_faqs = false))
    },
    fetchMoreFaqs() {
      this.fetching_more_faqs = true
      request
        .get(this.faqs_next_page_url)
        .then(({ data }) => {
          this.faqs.push(...data.data)
          this.faqs_next_page_url = data.next_page_url
        })
        .finally(() => (this.fetching_more_faqs = false))
    },
    savePost(payload) {
      this.handleSavePost(payload, (data) => {
        this.post_dialog = false
        if (this.activePostType === 'faqs') {
          this.faqs.unshift(data.data)
        }
        this.appSnackbar('Post created')
      })
    },
    updatePost(payload) {
      this.handleUpdatePost(this.activePost.id, payload, (data) => {
        this.post_dialog = false
        if (data.data.type === 'faqs') {
          let index = this.faqs.findIndex((i) => i.id == data.data.id)
          if (~index) this.faqs.splice(index, 1, data.data)
        }
        this.activePost = data.data
        this.appSnackbar('Post updated')
      })
    },
    handleEdit(entry) {
      this.activePost = entry
      this.activePostType = entry.type
      this.post_dialog = true
    },
    handleCreate(type) {
      this.activePost = null
      this.activePostType = type
      this.post_dialog = true
    },
    handleDelete(item) {
      this.handleDeletePost(item, (data) => {
        if (item.type === 'faqs') {
          let index = this.faqs.findIndex((i) => i.id === item.id)
          if (~index) this.faqs.splice(index, 1)
        }
      })
    }
  }
}
</script>
<style lang="scss">
.faqs-supports {
  .v-expansion-panel-content {
    .v-expansion-panel-content__wrap {
      padding: 10px !important;
    }
  }
}
</style>
